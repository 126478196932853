import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./containers/Home"

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
