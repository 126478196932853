import React, { Component } from "react";
import { Link } from "react-scroll";

import { checkValidity } from "../../shared/validations";

import LogoWhite from "../../components/Icons/Logo-white";
import ReliabilityIcon from "../../components/Icons/ReliabilityIcon";
import IntegrityIcon from "../../components/Icons/IntegrityIcon";
import EfficiencyIcon from "../../components/Icons/EfficiencyIcon";
import DignityIcon from "../../components/Icons/DignityIcon";
import Call2actIcon from "../../components/Icons/Call2actIcon";
import LogoIconLight from "../../components/Icons/LogoIconLight";
import StarIcon from "../../components/Icons/StarIcon";
import Button from "../../components/Form/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";

//services icons
import FumigateIcon from "../../components/Icons/services/Fumigate";
import UsherIcon from "../../components/Icons/services/Usher";
import DeliveryIcon from "../../components/Icons/services/Delivery";
import MovingHelpIcon from "../../components/Icons/services/MovingHelp";
import HouseCleaningIcon from "../../components/Icons/services/HouseCleaning";
import GeneratorRepairsIcon from "../../components/Icons/services/GeneratorRepairs";
import GardeningIcon from "../../components/Icons/services/Gardening";
import MakeupIcon from "../../components/Icons/services/Makeup";
import BarbingIcon from "../../components/Icons/services/Barbing";
import CarWashIcon from "../../components/Icons/services/CarWash";
import TilerIcon from "../../components/Icons/services/Tiler";
import LaunIronIcon from "../../components/Icons/services/LaunIron";
import HousePaintingIcon from "../../components/Icons/services/HousePainting";
import HairDressingIcon from "../../components/Icons/services/HairDressing";
import MasonryIcon from "../../components/Icons/services/Masonry";
import MechanicIcon from "../../components/Icons/services/Mechanic";
import CarpentaryIcon from "../../components/Icons/services/Carpentary";
import ElectricianIcon from "../../components/Icons/services/Electrician";
import BodyMassagesIcon from "../../components/Icons/services/BodyMassages";
import ErrandsIcon from "../../components/Icons/services/Errands";
import PlumbingIcon from "../../components/Icons/services/Plumbing";
import AirConditioningIcon from "../../components/Icons/services/AirConditioning";
import RefrigerationIcon from "../../components/Icons/services/Refrigeration";
import ElevatorIcon from "../../components/Icons/services/Elevator";

import HeaderBg from "../../assets/images/header_bg.png";

class Home extends Component {
  state = {
    joinWaitlistForm: {
      fullName: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      gender: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      email: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      phone: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      engagement: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      others: {
        value: "",
        valid: true,
        focused: false,
        messageClassName: "none",
      },
      location: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
    isModalOpen: false,
    isCompleteOpen: false,
  };

  handleToggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleToggleComplete = () => {
    this.setState({ isCompleteOpen: !this.state.isCompleteOpen });
  };

  handleInputOnChange = (event, elementId, validations) => {
    const value = event.target.value;
    let valid = checkValidity(value, validations);

    if (elementId === "others" && value === "") {
      valid = true;
    }

    const updatedFormElement = {
      ...this.state.joinWaitlistForm[elementId],
      value,
      valid,
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.joinWaitlistForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      joinWaitlistForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState) => {
    const updatedNameObject = {
      ...this.state.joinWaitlistForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.joinWaitlistForm,
      [name]: updatedNameObject,
    };

    this.setState({
      joinWaitlistForm: updatedForm,
    });
  };

  encode = (data: Object) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  handleOnJoinWaitlist = (e) => {
    e.preventDefault();
    const form = e.target;
    const joinWaitlistForm = this.state.joinWaitlistForm;

    if (
      joinWaitlistForm.fullName.valid &&
      joinWaitlistForm.fullName.value !== "" &&
      joinWaitlistForm.email.valid &&
      joinWaitlistForm.email.value !== "" &&
      joinWaitlistForm.phone.valid &&
      joinWaitlistForm.phone.value !== "" &&
      joinWaitlistForm.gender.valid &&
      joinWaitlistForm.gender.value !== "" &&
      joinWaitlistForm.engagement.valid &&
      joinWaitlistForm.engagement.value !== "" &&
      joinWaitlistForm.location.valid &&
      joinWaitlistForm.location.value !== ""
    ) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          fullName: joinWaitlistForm.fullName.value,
          email: joinWaitlistForm.email.value,
          phone: joinWaitlistForm.phone.value,
          gender: joinWaitlistForm.gender.value,
          engagement: joinWaitlistForm.engagement.value,
          location: joinWaitlistForm.location.value,
          others: joinWaitlistForm.others.value
        }),
      })
        .then(() => {
          this.setState({ formIsValid: false });
          this.handleToggleComplete();
          window.alert(
            "You have successfully joined the waitlist. You are our priority and we would inform you once we launch!"
          );
        })
        .catch((error) =>
          alert("An error occured while sending your joining waitlist!")
        );
    } else {
      window.alert("Invalid form submission");
    }
  };

  render() {
    const joinWaitlistForm = this.state.joinWaitlistForm;

    return (
      <div className="Home">
        <div className="Home__nav">
          <Link
            to="top"
            className="Home__nav__item"
            activeClass="Home__nav__item--active"
            spy={true}
            smooth={true}
            duration={800}
          >
            <div className="Home__nav__item--inner"></div>
          </Link>
          <Link
            to="whoAreWe"
            className="Home__nav__item"
            activeClass="Home__nav__item--active"
            spy={true}
            smooth={true}
            duration={800}
          >
            <div className="Home__nav__item--inner"></div>
          </Link>
          <Link
            to="whyUs"
            className="Home__nav__item"
            activeClass="Home__nav__item--active"
            spy={true}
            smooth={true}
            duration={800}
          >
            <div className="Home__nav__item--inner"></div>
          </Link>
        </div>

        <header id="top">
          <div className="Home__header">
            <div className="Home__header__darkbg"></div>
            <div className="Home__header__img">
              <img src={HeaderBg} alt="Header bg" />
            </div>
          </div>
          <div className="Home__header__overlay">
            <div className="Home__header__logo">
              <LogoWhite />
            </div>

            <div className="Home__header__content">
              <h1>Tunse! Get Dignified And Reliable Experts</h1>
              <br />
              <p>
                We are on a mission to create a marketplace that brings together
                basic handy service providers and customers in need of their
                services.
              </p>
              <br />

              <div className="Home__header__btn">
                <Button onClick={() => this.handleToggleModal()}>
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </header>
        <section id="whoAreWe" className="Home__whoAreWe">
          <div className="Home__whoAreWe__inner">
            <h1>
              Who we are & <br /> <span>What we do</span>{" "}
            </h1>
            <br />
            <p>
              Tunse is a platform that connects Artisans to Clients with ease by
              leveraging on the power of Digital Technology. <br /> We connect
              clients with those who can get the job done via our platform. Our
              utmost priority is to make your life easier by linking you up with
              efficient service providers (<b>T-Workers</b>). We are the "
              <b>E-Commerce of Labour</b>”.
            </p>

            <div className="Home__whoAreWe__services">
              <h3>Our Services</h3>

              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#232332" }}
                    >
                      <FumigateIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Fumigation
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{
                        backgroundColor: "#BE6E8E",
                        border: "1px solid #fff",
                      }}
                    >
                      <UsherIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Ushers
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#EEFFE2" }}
                    >
                      <DeliveryIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Delivery
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#F9AA33" }}
                    >
                      <MovingHelpIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Moving Help
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#8EBE6E" }}
                    >
                      <HouseCleaningIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Home Cleaning
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#F9AA33" }}
                    >
                      <GeneratorRepairsIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Generator Repairs
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#232332" }}
                    >
                      <GardeningIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Gardener
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#EEFFE2" }}
                    >
                      <MakeupIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Makeup
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#F9AA33" }}
                    >
                      <BarbingIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Barber
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{
                        backgroundColor: "#BE6E8E",
                        border: "1px solid #fff",
                      }}
                    >
                      <CarWashIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Car Wash
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#8EBE6E" }}
                    >
                      <TilerIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Tiler
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#8EBE6E" }}
                    >
                      <LaunIronIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Laundry and Ironing
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#EEFFE2" }}
                    >
                      <HousePaintingIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      House Painter
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#232332" }}
                    >
                      <HairDressingIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Hair Stylist
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{
                        backgroundColor: "#BE6E8E",
                        border: "1px solid #fff",
                      }}
                    >
                      <MasonryIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Masonry/Bricklayer
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#F9AA33" }}
                    >
                      <MechanicIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Mechanic
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#8EBE6E" }}
                    >
                      <CarpentaryIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Carpenter
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#232332" }}
                    >
                      <ElectricianIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Electrician
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#8EBE6E" }}
                    >
                      <BodyMassagesIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Body Massages
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#232332" }}
                    >
                      <ErrandsIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Errands
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#F9AA33" }}
                    >
                      <PlumbingIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Plumber
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#EEFFE2" }}
                    >
                      <AirConditioningIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      AC Repair
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{
                        backgroundColor: "#BE6E8E",
                        border: "1px solid #fff",
                      }}
                    >
                      <RefrigerationIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Refrigeration Technician
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div
                    className="Home__whoAreWe__services__item"
                    onClick={() => this.handleToggleModal()}
                  >
                    <div
                      className="Home__whoAreWe__services__item__icon"
                      style={{ backgroundColor: "#8EBE6E" }}
                    >
                      <ElevatorIcon />
                    </div>
                    <div className="Home__whoAreWe__services__item__text">
                      Elevator Repair
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="whyUs" className="Home__whyUs">
          <div className="Home__whyUs__inner">
            <h1>Why Us</h1>
            <br />
            <div className="Home__whyUs__items">
              <div className="row">
                <div className="col-md-6">
                  <div className="Home__whyUs__item">
                    <ReliabilityIcon />
                    <div className="Home__whyUs__item__content">
                      <h4>Reliability</h4>
                      <p>
                        This is one of the core values at Tunse, you can trust
                        us to always deliver!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Home__whyUs__item">
                    <IntegrityIcon />
                    <div className="Home__whyUs__item__content">
                      <h4>Integrity</h4>
                      <p>
                        Our word is our bond! if we say we will deliver, we will
                        at all cost.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Home__whyUs__item">
                    <EfficiencyIcon />
                    <div className="Home__whyUs__item__content">
                      <h4>Efficiency</h4>
                      <p>
                        Timeliness, a value as old as time itself and valuable
                        as money is at the fore-front of our services
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Home__whyUs__item">
                    <DignityIcon />
                    <div className="Home__whyUs__item__content">
                      <h4>Dignity</h4>
                      <p>
                        We all at the core of our being desire to be cherished,
                        respected and dignified as royals. With tunse, you are
                        one.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Home__call2act">
              <Call2actIcon />
              <div className="Home__call2act__content">
                <h1>
                  Get the app now!
                  <br />
                  You click it! we fix it!
                </h1>{" "}
                <br />
                <p>
                  It only takes a minute to start benefiting the goodness of our{" "}
                  <b>one click fix</b> solution!
                </p>
                <br />
                <Button
                  color="brand--dark"
                  onClick={() => this.handleToggleModal()}
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className="Home__footer">
              <LogoIconLight />
              <div className="Home__footer__links">
                <a
                  href="https://instagram.com/tunse_inc"
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>

                <a
                  href="https://twitter.com/tunse_inc"
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>

                <a
                  href="mailto:info@tunse.tech"
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  Contact
                </a>

                <a
                  href="mailto:info@tunse.tech"
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  Legal
                </a>
              </div>
            </div>
          </div>
        </section>

        <Modal
          onClose={() => this.handleToggleModal()}
          visible={this.state.isModalOpen}
        >
          <div className="Home__started">
            <h2>Waiting List</h2>
            <small>
              please fill the form below to give us a sense of what your needs
              are.
            </small>
            <br />

            <form
              name="Waitlist"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleOnJoinWaitlist}
            >
              <input type="hidden" name="form-name" value="Waitlist" />
              <Input
                label="Full Name"
                attributes={{
                  type: "text",
                  name: "fullName",
                  required: true,
                  theme: "default",
                  value: joinWaitlistForm.fullName.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "fullName", {
                      required: true,
                      isFullName: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("fullName", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("fullName", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.fullName.valid}
                focused={joinWaitlistForm.fullName.focused}
                message={
                  !joinWaitlistForm.fullName.valid &&
                  !joinWaitlistForm.fullName.focused &&
                  joinWaitlistForm.fullName.value !== ""
                    ? "Your fullName is required!"
                    : ""
                }
                messageClassName={joinWaitlistForm.fullName.messageClassName}
              />

              <Select
                label="Gender"
                attributes={{
                  type: "text",
                  name: "gender",
                  required: true,
                  theme: "default",
                  options: [
                    { value: "", display: "--Select Gender--" },
                    { value: "Male", display: "Male" },
                    { value: "Female", display: "Female" },
                    {
                      value: "Prefer not to disclose",
                      display: "Prefer not to disclose",
                    },
                  ],
                  value: joinWaitlistForm.gender.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "gender", {
                      required: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("gender", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("gender", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.gender.valid}
                focused={joinWaitlistForm.gender.focused}
                message={
                  !joinWaitlistForm.gender.valid &&
                  !joinWaitlistForm.gender.focused &&
                  joinWaitlistForm.gender.value !== ""
                    ? "Your gender is required!"
                    : ""
                }
                messageClassName={joinWaitlistForm.gender.messageClassName}
              />

              <Input
                label="Email Address"
                attributes={{
                  type: "email",
                  name: "email",
                  required: true,
                  theme: "default",
                  value: joinWaitlistForm.email.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "email", {
                      required: true,
                      isEmail: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("email", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("email", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.email.valid}
                focused={joinWaitlistForm.email.focused}
                message={
                  !joinWaitlistForm.email.valid &&
                  !joinWaitlistForm.email.focused &&
                  joinWaitlistForm.email.value !== ""
                    ? "A valid email address is required!"
                    : ""
                }
                messageClassName={joinWaitlistForm.email.messageClassName}
              />

              <Input
                label="Phone Number"
                attributes={{
                  type: "text",
                  name: "phone",
                  placeholder: "e.g +23483.....",
                  required: true,
                  theme: "default",
                  value: joinWaitlistForm.phone.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "phone", {
                      required: true,
                      isPhone: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("phone", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("phone", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.phone.valid}
                focused={joinWaitlistForm.phone.focused}
                message={
                  !joinWaitlistForm.phone.valid &&
                  !joinWaitlistForm.phone.focused &&
                  joinWaitlistForm.phone.value !== ""
                    ? "A valid phone number with country code is required!"
                    : ""
                }
                messageClassName={joinWaitlistForm.phone.messageClassName}
              />

              <Select
                label="How do you intend to engage with the platform?"
                attributes={{
                  type: "text",
                  name: "engagement",
                  required: true,
                  theme: "default",
                  options: [
                    { value: "", display: "--Select Option--" },
                    { value: "Fumigation", display: "Fumigation" },
                    { value: "Ushers", display: "Ushers" },
                    { value: "Delivery", display: "Delivery" },
                    { value: "Moving Help", display: "Moving Help" },
                    { value: "Home Cleaning", display: "Home Cleaning" },
                    {
                      value: "Generator Repairs",
                      display: "Generator Repairs",
                    },
                    { value: "Gardener", display: "Gardener" },
                    { value: "Makeup", display: "Makeup" },
                    { value: "Barber", display: "Barber" },
                    { value: "Car Wash", display: "Car Wash" },
                    { value: "Tiler", display: "Tiler" },
                    {
                      value: "Laundry and Ironing",
                      display: "Laundry and Ironing",
                    },
                    { value: "House Painter", display: "House Painter" },
                    { value: "Hair Stylist", display: "Hair Stylist" },
                    {
                      value: "Masonry/Bricklayer",
                      display: "Masonry/Bricklayer",
                    },
                    { value: "Mechanic", display: "Mechanic" },
                    { value: "Carpenter", display: "Carpenter" },
                    { value: "Electrician", display: "Electrician" },
                    { value: "Body Massages", display: "Body Massages" },
                    { value: "Errands", display: "Errands" },
                    { value: "Plumber", display: "Plumber" },
                    { value: "AC Repair", display: "AC Repair" },
                    { value: "Refrigeration Technician", display: "Refrigeration Technician" },
                    { value: "Elevator Repair", display: "Elevator Repair" },
                    {
                      value: "Others",
                      display: "Others",
                    },
                  ],
                  value: joinWaitlistForm.engagement.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "engagement", {
                      required: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("engagement", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("engagement", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.engagement.valid}
                focused={joinWaitlistForm.engagement.focused}
                message={
                  !joinWaitlistForm.engagement.valid &&
                  !joinWaitlistForm.engagement.focused &&
                  joinWaitlistForm.engagement.value !== ""
                    ? "Your engagement option is required!"
                    : ""
                }
                messageClassName={joinWaitlistForm.engagement.messageClassName}
              />

              <Input
                label="Others, Please specify"
                attributes={{
                  type: "text",
                  name: "others",
                  theme: "default",
                  value: joinWaitlistForm.others.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "others", {
                      required: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("others", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("others", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.others.valid}
                focused={joinWaitlistForm.others.focused}
                message={
                  !joinWaitlistForm.others.valid &&
                  !joinWaitlistForm.others.focused &&
                  joinWaitlistForm.others.value !== ""
                    ? "Please enlighten us on how you would like to engage with the platform!"
                    : ""
                }
                messageClassName={joinWaitlistForm.location.messageClassName}
                style={{
                  display:
                    joinWaitlistForm.engagement.value === "Others"
                      ? "block"
                      : "none",
                }}
              />

              <Input
                label="Location"
                attributes={{
                  type: "text",
                  name: "location",
                  required: true,
                  theme: "default",
                  value: joinWaitlistForm.location.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "location", {
                      required: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("location", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("location", {
                      focused: false,
                    }),
                }}
                hasError={!joinWaitlistForm.location.valid}
                focused={joinWaitlistForm.location.focused}
                message={
                  !joinWaitlistForm.location.valid &&
                  !joinWaitlistForm.location.focused &&
                  joinWaitlistForm.location.value !== ""
                    ? "Your location is required!"
                    : ""
                }
                messageClassName={joinWaitlistForm.location.messageClassName}
              />

              <div className="Home__started__btn">
                <Button disabled={!this.state.formIsValid}>
                  Join Waitlist
                </Button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          width="small"
          isCloseable={false}
          visible={this.state.isCompleteOpen}
        >
          <div className="Home__started__complete">
            <StarIcon />
            <br />
            <h3>Successfully Joined</h3>
            <p>
              Your details have been successfully captured and would be
              considered while creating this amazing solution for you and your
              business
            </p>
            <br />
            <Button type="button" onClick={() => this.handleToggleComplete()}>
              Done
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Home;
